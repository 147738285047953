import React, { useEffect, useRef, useState } from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation, withTranslation } from 'react-i18next'
import mixpanel from 'shared-components/utils/mixpanel'
import ProfileListItem from 'components/common/ProfileListItem'
import NoResults from 'svg/NoResults'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenBashFrom } from 'actions/event'
import dayjs from 'dayjs'
import FlatButton from 'shared-components/buttons/FlatButton'
import { followUser } from 'actions/user'
import InfiniteScroll from 'react-infinite-scroller'
import { openUserInfo } from '../../actions/modals'
import { useIsLoggedIn } from '../../utils/hooks'
import EventAvatar from './EventAvatar'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'hidden',
  },
  event: {
    width: '100%',
  },
  noResults: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
  },
  text: {
    marginTop: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(1),
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  eventCard: {
    display: 'flex',
    padding: theme.spacing(1),
    cursor: 'pointer',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  textSection: {
    textAlign: 'left',
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
  friendRequestButton: {
    height: '32px',
    padding: '0 12px',
    borderRadius: '8px',
  },
  highlightedItem: {
    backgroundColor: theme.palette.grey[150],
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.5, 0),
    margin: theme.spacing(0.5, 0),
    boxSizing: 'border-box',
  },
  item: {
    padding: theme.spacing(0.5, 0),
    margin: theme.spacing(0.5, 0),
    borderRadius: theme.spacing(1),
    boxSizing: 'border-box',
  },
  scrollContainer: {
    overflowY: 'auto',
    flex: 1,
    padding: theme.spacing(0, 2),
    height: '100%',
    '-webkit-overflow-scrolling': 'touch',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    width: '100%',
  },
}))

const AddFriendButton = ({ account, t }) => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [newState, setNewState] = useState(null)
  const add = async () => {
    // setLoading(true)
    mixpanel.track('Add Friend', {
      'Of User ID': account.id,
      'Location in App': 'user search',
      'Was Suggested': false,
    })
    const friend = {
      ofUser: account,
    }
    dispatch(followUser(account.id))
    setNewState('REQUEST_SENT')
    // dispatch(addFriend(friend))
  }

  const getString = () => {
    if (newState !== null) {
      return newState === 'REQUEST_SENT' ? t('requested') : t('friends')
    } else {
      switch (account.friendStatus) {
        case null:
        case 'NONE':
          return t('addFriend')
        case 'REQUEST_SENT':
          return t('requested')
        case 'FRIEND':
          return t('friends')
        default:
          return t('addFriend')
      }
    }
  }

  return (
    <div>
      <FlatButton
        loading={loading}
        onClick={add}
        className={classes.friendRequestButton}
        color='primary'
        disabled={getString() !== t('addFriend')}
      >
        {/* <PersonAdd className={classes.addIcon} color='primary' /> */}
        {getString()}
        {/* {t('add')} */}
      </FlatButton>
    </div>
  )
}
function getYear() {
  const today = new Date()
  const year = today.getFullYear()
  return `${year}`
}

const EventSearchResult = ({ event, t, goToEvent }) => {
  const { classes } = useStyles()
  const isPublicEvent = event.privacyType === 'PUBLIC'
  const [currentYear, setCurrentYear] = useState(getYear())
  return (
    <>
      <div className={classes.eventCard} onClick={goToEvent}>
        <EventAvatar
          size={40}
          event={event}
          fallback={
            isPublicEvent ? event.organisation : event?.hosts?.[0]?.model
          }
          className={classes.avatar}
          shadow
        />
        <div className={classes.textSection}>
          <Typography variant='subtitle2'>{event.name}</Typography>
          <Typography
            style={{ marginTop: 2 }}
            variant='caption'
            color='textSecondary'
          >
            {event.dateToBeAnnounced
              ? t('eventSections.tba')
              : event.startDate < currentYear
                ? dayjs(event.startDate).format('dddd D MMMM YYYY')
                : dayjs(event.startDate).format('dddd D MMMM')}
          </Typography>
        </div>
      </div>
    </>
  )
}

const PAGE_SIZE = 20

export const SearchResultItem = ({
  model,
  goToEvent,
  goToPage,
  goToProfile,
  hideAddFriendButton,
}) => {
  const { t } = useTranslation('common')
  const { classes } = useStyles()

  if (model.type === 'EVENT') {
    return (
      <EventSearchResult
        key={model.model.id}
        t={t}
        classes={classes}
        event={model.model}
        goToEvent={() => goToEvent(model.model)}
      />
    )
  } else if (model.type === 'ORGANISATION') {
    return (
      <ProfileListItem
        key={model.model.id}
        profile={model.model}
        onClick={goToPage}
        showFriendsFollowing
        large
      />
    )
  } else if (model.type === 'USER') {
    const shouldShowAdd = ['NONE', 'REQUEST_RECEIVED'].includes(
      model.model.friendStatus,
    )
    // !friends.some(friend => friend.ofUser.id === model.model.id)
    return (
      <div key={model.model.id} className={classes.account}>
        <ProfileListItem
          onClick={goToProfile}
          profile={model.model}
          buttonSection={
            !hideAddFriendButton && shouldShowAdd ? (
              <AddFriendButton classes={classes} account={model.model} t={t} />
            ) : null
          }
          large
        />
      </div>
    )
  }
}

const SearchResultsTop = ({
  t,
  query,
  fetchSearch,
  closeModal,
  onResultClick,
}) => {
  const { classes } = useStyles()
  const router = useRouter()
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const dispatch = useDispatch()
  const itemRefs = useRef([])
  const [focusedIndex, setFocusedIndex] = useState(-1)
  const isLoggedIn = useIsLoggedIn()

  const friendsState = useSelector((state) => state.user.friends)
  const friends = friendsState.friends

  useEffect(() => {
    setResults([])
    setHasMore(true)
  }, [query])

  const goToEvent = (event) => {
    closeModal()
    if (onResultClick) {
      onResultClick({ type: 'EVENT', model: event })
    }
    dispatch(setOpenBashFrom('search'))
    // if (event.creationType === 'EXTERNAL') {
    if (event.hasJoinOptions === false) {
      const firstHostThatIsOrganisation =
        event.hosts?.find((h) => h.type === 'ORGANISATION')?.model ?? {}
      if (firstHostThatIsOrganisation?.username) {
        router.push(
          `/${firstHostThatIsOrganisation.username}?eventId=${event.id}`,
        )
      } else if (firstHostThatIsOrganisation?.id) {
        router.push(
          `/pages/${firstHostThatIsOrganisation.id}?eventId=${event.id}`,
        )
      }
    } else if (event.code) {
      router.push(`/e/${event.code}`)
    } else {
      router.push(`/events/${event.id}`)
    }
  }

  useEffect(() => {
    if (focusedIndex !== -1 && itemRefs.current[focusedIndex]) {
      itemRefs.current[focusedIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [focusedIndex])

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault()
          setFocusedIndex((prevIndex) =>
            Math.min(prevIndex + 1, results.length - 1),
          )
          break
        case 'ArrowUp':
          event.preventDefault()
          setFocusedIndex((prevIndex) => Math.max(prevIndex - 1, 0))
          break
        case 'Enter':
          if (focusedIndex >= 0 && focusedIndex < results.length) {
            const item = results[focusedIndex]
            if (item.type === 'EVENT') {
              goToEvent(item.model)
            } else if (item.type === 'ORGANISATION') {
              goToProfile(item.model)
            } else if (item.type === 'USER') {
              if (isLoggedIn) {
                router.push(`/users/${item.model.id}`)
              } else {
                dispatch(openUserInfo(item.model))
              }
            }
          }
          break
        default:
          break
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [results, focusedIndex])

  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, results.length)
  }, [results])

  const goToProfile = (page) => {
    closeModal()
    if (onResultClick) {
      onResultClick({ type: 'ORGANISATION', model: page })
    }
    dispatch(setOpenBashFrom('search'))
    if (page.username) {
      router.push(`/${page.username}`)
    } else {
      router.push(`/pages/${page.id}`)
    }
  }

  const loadMore = async () => {
    setLoading(true)
    const page = Math.floor(results.length / PAGE_SIZE)
    const newResults = await fetchSearch(query, page, PAGE_SIZE)
    setHasMore(newResults.length === PAGE_SIZE)
    setResults([...results, ...newResults])
    setLoading(false)
  }

  const searchResults = (model) => {
    if (model.type === 'EVENT') {
      return (
        <EventSearchResult
          key={model.model.id}
          t={t}
          classes={classes}
          event={model.model}
          goToEvent={() => goToEvent(model.model)}
        />
      )
    } else if (model.type === 'ORGANISATION') {
      return (
        <ProfileListItem
          key={model.model.id}
          profile={model.model}
          onClick={goToProfile}
          showFriendsFollowing
          large
        />
      )
    } else if (model.type === 'USER') {
      const shouldShowAdd = ['NONE', 'REQUEST_RECEIVED'].includes(
        model.model.friendStatus,
      )
      // !friends.some(friend => friend.ofUser.id === model.model.id)

      const handleUserClick = () => {
        closeModal()
        if (onResultClick) {
          onResultClick({ type: 'USER', model: model.model })
        }
        if (isLoggedIn) {
          router.push(`/users/${model.model.id}`)
        } else {
          dispatch(openUserInfo(model.model))
        }
      }

      return (
        <div key={model.model.id} className={classes.account}>
          <ProfileListItem
            profile={model.model}
            onClick={handleUserClick}
            buttonSection={
              shouldShowAdd ? (
                <AddFriendButton
                  classes={classes}
                  account={model.model}
                  t={t}
                />
              ) : null
            }
            large
          />
        </div>
      )
    }
  }

  return (
    <div className={classes.root}>
      <InfiniteScroll
        key={query}
        pageStart={-1}
        loadMore={loadMore}
        useWindow={false}
        hasMore={results.length % PAGE_SIZE === 0 && !loading && hasMore}
        className={classes.scrollContainer}
      >
        {/* Correctly map over results a single time */}
        {results.map((item, index) => {
          const isSelected = index === focusedIndex
          return (
            <div
              key={item.model.id} // Ensure unique keys for each item
              ref={(el) => (itemRefs.current[index] = el)}
              className={isSelected ? classes.highlightedItem : classes.item}
            >
              {searchResults(item)}
            </div>
          )
        })}

        {/*{query.trim() === '' ?*/}
        {/*(<div className={classes.noResults}>*/}
        {/*<NoResults /></div>) : */}
        {!loading && !hasMore && results.length < 1 && query.length > 0 && (
          <div className={classes.noResults}>
            <NoResults />

            <Typography className={classes.text} variant='subtitle1'>
              {t('searchResults.noResults')}
            </Typography>
            <Typography className={classes.subtitle} variant='body2'>
              {t('searchResults.noneFoundProfile')}
              <b>{query}.</b>
            </Typography>
          </div>
        )}

        {loading && (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}
      </InfiniteScroll>
    </div>
  )
}

SearchResultsTop.propTypes = {}

export default withTranslation('common')(SearchResultsTop)
